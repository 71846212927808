import { GET_BRANDS } from "../actionTypes";

const initialValue = {
  brands: [],
};

function getBrandsReducer(state = initialValue, action) {
  if (action.type === GET_BRANDS) {
    return { ...state, brands: action.payload };
  }
  return state;
}

export default getBrandsReducer;
