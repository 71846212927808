import { GET_FILTER_ID_GCT } from "../actionTypes";

const initialState = {
	filterIdGct: [],
};

function getFilterIdGct(state = initialState, action) {
	if (action.type === GET_FILTER_ID_GCT) {
		return { ...state, filterIdGct: action.payload };
	}
	return state;
}

export default getFilterIdGct;