import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/BudgetSubmission/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import getAllDataReducer from "../store/reducers/getAllDataReducer";
import getAllBrandReducer from "../store/reducers/getAllBrandReducer";
import getAllBudgetTypeReducer from "../store/reducers/getAllBudgetTypeReducer";
import getAllGroupCostTypeReducer from "../store/reducers/getAllGroupCostTypeReducer";
import getAllDataTransferReducer from "../store/reducers/getAllTransferBudgetReducer";
import getAllApprovalBudgetsReducer from "../store/reducers/getAllApprovalBudgetsReducer";
import getAllApprovalTransferReducer from "../store/reducers/getAllApprovalTransferBudget";
import getAllUserReducer from "../store/reducers/getAllUserReducer";
import getAllDepartmentReducer from "../store/reducers/getAllDepartmentReducer";
import getFilterDataBudgetActiveReducer from "../store/reducers/getFilterDataBudgetActiveReducer";
import getAllDataActivityReducer from "../store/reducers/getAllDataActivityReducer";
import getAllProjectReducer from "../store/reducers/getAllProjectReducer";
import getAllSubCategoryReducer from "../store/reducers/getAllSubCategoryReducer";
import getFilterIdGctReducer from "../store/reducers/getFilterIdGctReducer";
import getHandleParentActivityReducer from "../store/reducers/getHandleParentActivityReducer";
import getChildActivityReducer from "../store/reducers/getChildActivityReducer";
import getAllApprovalActivityReducer from "../store/reducers/getAllApprovalActivityReducer";
import getBrandsReducer from "../store/reducers/getBrandsReducer";
import getAllRemaingReportReducer from "../store/reducers/getAllRemainingReportReducer";
import getAllDataActualReducer from "../store/reducers/getAllDataActualReducer";
import getAllYearReducer from "../store/reducers/getAllYearReducer";
import getShortMonthNames from "../store/reducers/getShortMonthsName";
import getKeysTransfer from "../store/reducers/getKeysTransfer";
import getMappingDataReducer from "../store/reducers/getMappingDataReducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  getAllData: getAllDataReducer,
  getAllBrand: getAllBrandReducer,
  getAllBudgetType: getAllBudgetTypeReducer,
  getAllGroupCostType: getAllGroupCostTypeReducer,
  getAllDataTransferBudget: getAllDataTransferReducer,
  getAllApprovalBudgets: getAllApprovalBudgetsReducer,
  getAllApprovalTransfer: getAllApprovalTransferReducer,
  getBrands: getBrandsReducer,
  getAllUser: getAllUserReducer,
  getAllDepartment: getAllDepartmentReducer,
  getFilterDataBudgetActive: getFilterDataBudgetActiveReducer,
  getAllDataActivity: getAllDataActivityReducer,
  getAllProject: getAllProjectReducer,
  getAllSubCategory: getAllSubCategoryReducer,
  getFilterIdGct: getFilterIdGctReducer,
  getHandleParentActivity: getHandleParentActivityReducer,
  getChildActivity: getChildActivityReducer,
  getAllApprovalActivity: getAllApprovalActivityReducer,
  remainingReport: getAllRemaingReportReducer,
  getAllDataActual: getAllDataActualReducer,
  getAllYear: getAllYearReducer,
  months: getShortMonthNames,
  keysTransfer: getKeysTransfer,
  getMappingData: getMappingDataReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
