import {
  GET_COST_TYPE_PERPAGE_PERLIMIT,
  GET_BUDGET_TYPE_PERPAGE_PERLMIT,
  GET_GROUP_COST_TYPE_PERPAGE_PERLIMIT,
  GET_BRAND_PERPAGE_PERLIMIT,
  GET_COMPANY_PERPAGE_PERLIMIT,
} from "../actionTypes";

const initialState = {
  costType: [],
  company: [],
};

function getMappingDataReducer(state = initialState, action) {
  if (action.type === GET_COST_TYPE_PERPAGE_PERLIMIT) {
    return { ...state, costType: action.payload };
  } else if (action.type === GET_COMPANY_PERPAGE_PERLIMIT) {
    return { ...state, company: action.payload };
  }
  return state;
}

export default getMappingDataReducer;
