import { GET_ALL_APPPROVAL_BUDGET } from "../actionTypes";

export function setDataAllApprovalBudgets(input) {
  return {
    type: GET_ALL_APPPROVAL_BUDGET,
    payload: input,
  };
}

export function fetchDataApprovalBudgets() {
  const idUser = localStorage.getItem("id_user");
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_URL}/approval_list/${idUser}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        dispatch(setDataAllApprovalBudgets(result.data));
      })
      .catch(() => {
        console.log("error");
      });
  };
}
