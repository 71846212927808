export const GET_ALL_DATA = "GET_ALL_DATA";
export const GET_ALL_BRAND = "GET_ALL_BRAND";
export const GET_ALL_BUDGET_TYPE = "GET_ALL_BUDGET_TYPE";
export const GET_ALL_GROUP_COST_TYPE = "GET_ALL_GROUP_COST_TYPE";
export const GET_ALL_TRANSFER_BUDGET = "GET_ALL_TRANSFER_BUDGET";
export const GET_ALL_APPPROVAL_BUDGET = "GET_ALL_APPPROVAL_BUDGET";
export const GET_ALL_APPROVAL_TRANSFER = "GET_ALL_APPROVAL_TRANSFER";
export const GET_BRANDS = "GET_BRANDS";
export const GET_ALL_USER = "GET_ALL_USER";
export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT";
export const GET_FILTER_DATA_BUDGET_ACTIVE = "GET_FILTER_DATA_BUDGET_ACTIVE";
export const GET_ALL_DATA_ACTIVITY = "GET_ALL_DATA_ACTIVITY";
export const GET_ALL_PROJECT = "GET_ALL_PROJECT";
export const GET_ALL_SUB_CATEGORY = "GET_ALL_SUB_CATEGORY";
export const GET_FILTER_ID_GCT = "GET_FILTER_ID_GCT";
export const GET_HANDLE_PARENT_ACTIVITY = "GET_HANDLE_PARENT_ACTIVITY";
export const GET_CHILD_ACTIVITY = "GET_CHILD_ACTIVITY";
export const GET_ALL_APPPROVAL_ACTIVITY = "GET_ALL_APPPROVAL_ACTIVITY";
export const GET_ALL_REMAINING_REPORT = "GET_ALL_REMAINING_REPORT";
export const GET_ALL_DATA_ACTUAL = "GET_ALL_DATA_ACTUAL";
export const GET_ALL_YEAR = "GET_ALL_YEAR";
export const GET_COMPANY_PERPAGE_PERLIMIT = "GET_COMPANY_PERPAGE_PERLIMIT";
export const GET_BRAND_PERPAGE_PERLIMIT = "GET_BRAND_PERPAGE_PERLIMIT";
export const GET_COST_TYPE_PERPAGE_PERLIMIT = "GET_COST_TYPE_PERPAGE_PERLIMIT";
export const GET_BUDGET_TYPE_PERPAGE_PERLMIT = "GET_BUDGET_TYPE_PERPAGE_PERLMIT";
export const GET_GROUP_COST_TYPE_PERPAGE_PERLIMIT = "GET_GROUP_COST_TYPE_PERPAGE_PERLIMIT";
