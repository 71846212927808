import { GET_ALL_DATA_ACTUAL } from "../actionTypes";

const initialState = {
  actuals: [],
};

function getAllDataActualReducer(state = initialState, action) {
  if (action.type === GET_ALL_DATA_ACTUAL) {
    return { ...state, actuals: action.payload };
  }
  return state;
}

export default getAllDataActualReducer;
