import { GET_ALL_GROUP_COST_TYPE } from "../actionTypes";

const initialState = {
  groupCostTypes: [],
};

function getAllGroupCostTypeReducer(state = initialState, action) {
  if (action.type === GET_ALL_GROUP_COST_TYPE) {
    return { ...state, groupCostTypes: action.payload };
  }
  return state;
}

export default getAllGroupCostTypeReducer;