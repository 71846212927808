import { GET_ALL_TRANSFER_BUDGET } from "../actionTypes";

const initialState = {
  budgets: [],
};

function getAllDataTransferReducer(state = initialState, action) {
  if (action.type === GET_ALL_TRANSFER_BUDGET) {
    return { ...state, budgets: action.payload };
  }
  return state;
}

export default getAllDataTransferReducer;
