import { GET_ALL_APPROVAL_TRANSFER } from "../actionTypes";

const initialValue = {
  approvalTransfer: [],
};

function getAllApprovalTransferReducer(state = initialValue, action) {
  if (action.type === GET_ALL_APPROVAL_TRANSFER) {
    return { ...state, approvalTransfer: action.payload };
  }
  return state;
}

export default getAllApprovalTransferReducer;
