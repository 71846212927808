import { GET_ALL_USER } from "../actionTypes";

const initialValue = {
  users: [],
};

function getAllUserReducer(state = initialValue, action) {
  if (action.type === GET_ALL_USER) {
    return { ...state, users: action.payload };
  }
  return state;
}

export default getAllUserReducer;
