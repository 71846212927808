import { GET_ALL_APPROVAL_TRANSFER } from "../actionTypes";
import axios from "axios";

export function setAllApprovalTransfer(input) {
  return {
    type: GET_ALL_APPROVAL_TRANSFER,
    payload: input,
  };
}

export function fetchAllApprovalTransfer() {
  const idUser = localStorage.getItem("id_user");
  const user_token = localStorage.getItem("user_token");
  return async (dispatch) => {
    try {
      const { data } = await axios(
        `${process.env.REACT_APP_API_URL}/approval_list_transfer/${idUser}`,
        {
          method: "GET",
          headers: {
            token: user_token,
          },
        }
      );
      dispatch(setAllApprovalTransfer(data.data));
    } catch (error) {
      console.log(error);
    }
  };
}
