import { GET_ALL_REMAINING_REPORT } from "../actionTypes";

const initialState = {
  report: [],
};

function getAllRemaingReportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_REMAINING_REPORT:
      return { ...state, report: action.payload };
    default:
      return state;
  }
}

export default getAllRemaingReportReducer;
