import { GET_ALL_YEAR } from "../actionTypes";

const initialState = {
  years: [],
};

function getAllYearReducer(state = initialState, action) {
  if (action.type === GET_ALL_YEAR) {
    return { ...state, years: action.payload };
  }
  return state;
}

export default getAllYearReducer;