export const subMenuMapping = [
  {
    title: "User",
    link: "/mapping/list-user",
  },
  {
    title: "Approval",
    link: "/mapping/list-approval",
  },
  {
    title: "Project",
    link: "/mapping/list-project",
  },
  {
    title: "Analytic Account",
    link: "/mapping/list-analytic",
  },
  {
    title: "Cost Type - CoA",
    link: "/mapping/list-coa",
  },
  {
    title: "Brand",
    link: "/mapping/list-brand",
  },
];
