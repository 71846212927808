/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import axios from "axios";
import { Badge } from "@material-ui/core";
import { NotificationsNone } from "@material-ui/icons";
import "./notification.css";
import { fetchDataApprovalActivity } from "../../../../../store/actions/getAllApprovalActivityAction";
import { fetchDataApprovalBudgets } from "../../../../../store/actions/getAllApprovalBudgetsAction";
import { fetchAllApprovalTransfer } from "../../../../../store/actions/getAllApprovalTransferBudgetAction";
import { isEmpty } from "lodash";

export function UserProfileDropdown() {
  const [user, setUser] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  const user_token = localStorage.getItem("user_token");
  const [totalNotif, setTotalNotif] = useState(0);
  const [notifTransfer, setNotifTransfer] = useState(0);
  const [notifBudget, setNotifBudget] = useState(0);
  const [notifActivity, setNotifActivity] = useState(0);
  const { approvalTransfer } = useSelector(
    (state) => state.getAllApprovalTransfer
  );
  const { approvalBudgets } = useSelector(
    (state) => state.getAllApprovalBudgets
  );
  const { approvalActivity } = useSelector(
    (state) => state.getAllApprovalActivity
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [toggle, setToggle] = useState(false);
  const [isApprover, setIsApprover] = useState(false);

  const [dataApprovalClose, setDataApprovalClose] = useState([]);
  const [notifApprovalClose, setNotifApprovalClose] = useState(0);
  const [dataActivityReject, setDataActivityReject] = useState([]);
  const [notifActivityReject, setNotifActivityReject] = useState(0);
  const [dataActivityCommentary, setDataActivityCommentary] = useState([]);
  const [notifActivityCommentary, setNotifActivityCommentary] = useState(0);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/profile`,
        {
          headers: {
            token: user_token,
          },
        }
      );
      setUser(result.data.user);
      localStorage.setItem("id_user", result.data.user.id_user);
      localStorage.setItem("is_approver", result.data.user.is_approver);
      localStorage.setItem("id_role", result.data.user.id_role);
      setIsApprover(result.data.user.is_approver);
      setIsLoading(false);
      await dispatch(fetchDataApprovalActivity());
      await dispatch(fetchDataApprovalBudgets());
      await dispatch(fetchAllApprovalTransfer());
      await fetchDataApprovalClose();
      await fetchDataActivityReject();
      await fetchDataActivityCommentary();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataApprovalClose = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity_approver_close`,
        {
          headers: {
            token: localStorage.getItem("user_token"),
          },
        }
      );
      setDataApprovalClose(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataActivityReject = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/views/pagination?id_status_activity=10&id_pic=${localStorage.getItem("id_user")}`,
        {
          headers: {
            token: localStorage.getItem("user_token"),
          },
        }
      );
      setDataActivityReject(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchDataActivityCommentary = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/views/pagination?id_status_activity=18&id_pic=${localStorage.getItem("id_user")}`,
        {
          headers: {
            token: localStorage.getItem("user_token"),
          },
        }
      );
      setDataActivityCommentary(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCalculateNotif = ({ data, arrayStatus, idStatus }) => {
    let total = 0;
    data.forEach((item) => {
      if (!isEmpty(arrayStatus) && arrayStatus.includes(item[idStatus])) {
        total++;
      }
    });
    return total;
  };

  useEffect(() => {
    let totalQtyNotif = 0;
    let totalTransfer = 0;
    let totalBudgets = 0;
    let totalActivity = 0;
    let totalActivityClose = 0;
    if (isApprover) {
      const tempTransfer = [...approvalTransfer];
      const tempBudget = [...approvalBudgets];
      const tempActivity = [...approvalActivity];
      const tempActivityClose = [...dataApprovalClose];
      if (!isEmpty(tempTransfer)) {
        totalTransfer = handleCalculateNotif({
          data: tempTransfer,
          arrayStatus: [8, 5],
          idStatus: "id_status_budget",
        });
      }
      if (!isEmpty(tempBudget)) {
        totalBudgets = handleCalculateNotif({
          data: tempBudget,
          arrayStatus: [8, 5],
          idStatus: "id_status_budget",
        });
      }
      if (!isEmpty(tempActivity)) {
        totalActivity = handleCalculateNotif({
          data: tempActivity,
          arrayStatus: [4, 5],
          idStatus: "id_status_activity",
        });
      }
      if (!isEmpty(tempActivityClose)) {
        totalActivityClose = handleCalculateNotif({
          data: tempActivityClose,
          arrayStatus: [16, 17],
          idStatus: "id_status_activity_detail",
        });
      }

      totalQtyNotif =
        totalTransfer + totalActivity + totalBudgets + totalActivityClose;
      setNotifTransfer(totalTransfer);
      setNotifBudget(totalBudgets);
      setTotalNotif(totalQtyNotif);
      setNotifActivity(totalActivity);
      setNotifApprovalClose(totalActivityClose);
    }
  }, [
    approvalTransfer,
    approvalBudgets,
    approvalActivity,
    dataApprovalClose,
    isApprover,
  ]);

  useEffect(() => {
    let totalActivityReject = 0;
    const tempActivityReject = [...dataActivityReject];
    if (!isEmpty(tempActivityReject)) {
      totalActivityReject = handleCalculateNotif({
        data: tempActivityReject,
        arrayStatus: [10],
        idStatus: "id_status_activity",
      });
    }
    setNotifActivityReject(totalActivityReject);

    let totalActivityCommentary = 0;
    const tempActivityCommentary = [...dataActivityCommentary];
    if (!isEmpty(tempActivityCommentary)) {
      totalActivityCommentary = handleCalculateNotif({
        data: tempActivityCommentary,
        arrayStatus: [18],
        idStatus: "id_status_activity",
      });
    }
    setNotifActivityCommentary(totalActivityCommentary);

  }, [dataActivityReject,dataActivityCommentary]);

  function handleLogout() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/logout`, {
        headers: {
          token: user_token,
        },
      })
      .then(function(response) {
        if (response.data.success) {
          localStorage.clear();
          setTimeout(() => {
            window.location.assign("/auth/login");
          }, 1500);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const handleClickToApprovalPage = (page) => {
    setToggle(false);
    history.push(page);
  };
  return (
    <div>
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <Badge
            color="secondary"
            badgeContent={!isApprover ? notifActivityReject+notifActivityCommentary : totalNotif}
            style={{ position: "relative", marginTop: "0.5em" }}
            showZero
          >
            <NotificationsNone
              style={{ fontSize: "2em", cursor: "pointer" }}
              onClick={() => setToggle(!toggle)}
            />
            {toggle &&
              (isApprover ? (
                <div className="box">
                  <p
                    className="notif-title"
                    onClick={() =>
                      handleClickToApprovalPage(
                        "/budget-submission/approvalbudgets"
                      )
                    }
                  >
                    You have <span className="hover-custom">{notifBudget}</span>{" "}
                    approval budgets request
                  </p>
                  <p
                    className="notif-title"
                    onClick={() =>
                      handleClickToApprovalPage(
                        "/budget-submission/approvaltransfer"
                      )
                    }
                  >
                    You have{" "}
                    <span className="hover-custom">{notifTransfer}</span>{" "}
                    approval transfer request
                  </p>
                  <p
                    className="notif-title"
                    onClick={() =>
                      handleClickToApprovalPage(
                        "/activity-submission/approvalactivity"
                      )
                    }
                  >
                    You have{" "}
                    <span className="hover-custom">{notifActivity}</span>{" "}
                    approval activity request
                  </p>

                  <p
                    className="notif-title"
                    onClick={() =>
                      handleClickToApprovalPage(
                        "/activity-submission/approvalclose"
                      )
                    }
                  >
                    You have{" "}
                    <span className="hover-custom">{notifApprovalClose}</span>{" "}
                    approval close activity request
                  </p>
                </div>
              ) : (
                <div className="box">
                  <p
                    className="notif-title"
                    onClick={() =>
                      handleClickToApprovalPage(
                        "/activity-submission/activities?id_status_activity=10"
                      )
                    }
                  >
                    You have{" "}
                    <span className="hover-custom">{notifActivityReject}</span>{" "}
                    reject activity
                  </p>
                  <p
                    className="notif-title"
                    onClick={() =>
                      handleClickToApprovalPage(
                        "/activity-submission/activities?id_status_activity=18"
                      )
                    }
                  >
                    You have{" "}
                    <span className="hover-custom">{notifActivityCommentary}</span>{" "}
                    commentary activity
                  </p>
                </div>
              ))}
          </Badge>
          <div className="ml-1 mr-1" />

          <Dropdown drop="down" alignRight>
            <Dropdown.Toggle
              as={DropdownTopbarItemToggler}
              id="dropdown-toggle-user-profile"
            >
              <div
                className={
                  "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                }
              >
                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                  Hi,
                </span>{" "}
                <span className="text-primary font-weight-bolder font-size-base d-none d-md-inline mr-3">
                  <u>{user.full_name}</u>
                </span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
              <>
                {layoutProps.light && (
                  <>
                    <div className="d-flex align-items-center p-8 rounded-top">
                      <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                        <img
                          src={toAbsoluteUrl("/media/users/300_21.jpg")}
                          alt=""
                        />
                      </div>
                      <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                        {user.full_name}
                      </div>
                      <span className="label label-light-success label-lg font-weight-bold label-inline">
                        3 messages
                      </span>
                    </div>
                    <div className="separator separator-solid"></div>
                  </>
                )}

                {!layoutProps.light && (
                  <div
                    className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        "/media/misc/bg-1.jpg"
                      )})`,
                    }}
                  >
                    <div className="symbol bg-white-o-15 mr-3"></div>
                    <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                      {user.full_name}
                    </div>
                  </div>
                )}
              </>

              <div className="navi navi-spacer-x-0 pt-5">
                <Link
                  to="/user-profile"
                  className="navi-item px-8 cursor-pointer"
                >
                  <div className="navi-link">
                    <div className="navi-icon mr-2">
                      <i className="flaticon2-calendar-3 text-success" />
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold cursor-pointer">
                        My Profile
                      </div>
                      <div className="text-muted">
                        Account settings and more
                        <span className="label label-light-danger label-inline font-weight-bold">
                          update
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="navi-separator mt-3"></div>

                <div className="navi-footer  px-8 py-5">
                  <span
                    onClick={() => handleLogout()}
                    className="btn btn-light-primary font-weight-bold"
                  >
                    Sign Out
                  </span>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
}
