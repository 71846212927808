import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";

const BudgetPage = lazy(() =>
  import("./modules/BudgetSubmission/pages/BudgetPage")
);
const ActivityPage = lazy(() =>
  import("./modules/Activity/pages/ActivityPage")
);

const ActualPage = lazy(() => import("./modules/Actual/pages/ActualPage"));
const SettingPage = lazy(() => import("./modules/Setting"));
const ReportPage = lazy(() => import("./pages/ReportPage"));
const MappingPages = lazy(() => import("./modules/Mapping"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/budget-submission/budgets" />
        }
        <Route path="/report" component={ReportPage} />
        <Route path="/budget-submission" component={BudgetPage} />
        <Route path="/activity-submission" component={ActivityPage} />
        <Route path="/actual-odoo" component={ActualPage} />
        <Route path="/mapping" component={MappingPages} />
        <Route path="/setting" component={SettingPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
