import { GET_ALL_BRAND } from "../actionTypes";

const initialState = {
  brands: [],
};

function getAllBrandReducer(state = initialState, action) {
  if (action.type === GET_ALL_BRAND) {
    return { ...state, brands: action.payload };
  }
  return state;
}

export default getAllBrandReducer;