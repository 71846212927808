import { GET_FILTER_DATA_BUDGET_ACTIVE } from "../actionTypes";

const initialState = {
	filterBudgetActive: [],
};

function getFilterDataBudgetActive(state = initialState, action) {
	if (action.type === GET_FILTER_DATA_BUDGET_ACTIVE) {
		return { ...state, filterBudgetActive: action.payload };
	}
	return state;
}

export default getFilterDataBudgetActive;