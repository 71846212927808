import { GET_ALL_BUDGET_TYPE } from "../actionTypes";

const initialState = {
	budgetType: [],
};

function getAllBudgetTypeReducer(state = initialState, action) {
	if (action.type === GET_ALL_BUDGET_TYPE) {
		return { ...state, budgetType: action.payload };
	}
	return state;
}

export default getAllBudgetTypeReducer;