import { GET_ALL_APPPROVAL_ACTIVITY } from "../actionTypes";

const initialState = {
  approvalActivity: [],
};

function getAllApprovalActivityReducer(state = initialState, action) {
  if (action.type === GET_ALL_APPPROVAL_ACTIVITY) {
    return { ...state, approvalActivity: action.payload };
  }
  return state;
}

export default getAllApprovalActivityReducer;
