export const subMenuSettings = [
  {
    title: "Year",
    link: "/setting/setting-year",
  },
  {
    title: "Brand",
    link: "/setting/setting-brand",
  },
  {
    title: "Company",
    link: "/setting/setting-company",
  },
  {
    title: "Category",
    link: "/setting/setting-category",
  },
  {
    title: "Subcategory",
    link: "/setting/setting-subcategory",
  },
  {
    title: "Series",
    link: "/setting/setting-series",
  },
  {
    title: "Budget Type",
    link: "/setting/setting-budgettype",
  },
  {
    title: "Group Cost Type",
    link: "/setting/setting-groupcosttype",
  },
];
