import { GET_ALL_SUB_CATEGORY } from "../actionTypes";

const initialState = {
	subCategories: [],
};

function getAllSubCategoryReducer(state = initialState, action) {
	if (action.type === GET_ALL_SUB_CATEGORY) {
		return { ...state, subCategories: action.payload };
	}
	return state;
}

export default getAllSubCategoryReducer;