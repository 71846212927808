import { GET_ALL_PROJECT } from "../actionTypes";

const initialState = {
	projects: [],
};

function getAllProjectReducer(state = initialState, action) {
	if (action.type === GET_ALL_PROJECT) {
		return { ...state, projects: action.payload };
	}
	return state;
}

export default getAllProjectReducer;