import { GET_ALL_DATA } from "../actionTypes";

const initialState = {
  budgets: [],
};

function getAllDataReducer(state = initialState, action) {
  if (action.type === GET_ALL_DATA) {
    return { ...state, budgets: action.payload };
  }
  return state;
}

export default getAllDataReducer;