import { GET_ALL_DATA_ACTIVITY } from "../actionTypes";

const initialState = {
	activities: [],
};

function getAllDataActivityReducer(state = initialState, action) {
	if (action.type === GET_ALL_DATA_ACTIVITY) {
		return { ...state, activities: action.payload };
	}
	return state;
}

export default getAllDataActivityReducer;