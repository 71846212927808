import { GET_ALL_APPPROVAL_ACTIVITY } from "../actionTypes";
import axios from "axios";

export function setDataAllApprovalActivity(input) {
  return {
    type: GET_ALL_APPPROVAL_ACTIVITY,
    payload: input,
  };
}

export function fetchDataApprovalActivity() {
  const user_token = localStorage.getItem("user_token");
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/activity_approver`, {
        headers: {
          token: `${user_token}`,
        },
      })
      .then((res) => {
        dispatch(setDataAllApprovalActivity(res.data));
      })
      .catch(() => {
        console.log("error");
      });
  };
}
