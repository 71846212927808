import { GET_ALL_APPPROVAL_BUDGET } from "../actionTypes";

const initialState = {
	approvalBudgets: [],
};

function getAllApprovalBudgetsReducer(state = initialState, action) {
	if (action.type === GET_ALL_APPPROVAL_BUDGET) {
		return { ...state, approvalBudgets: action.payload };
	}
	return state;
}

export default getAllApprovalBudgetsReducer;