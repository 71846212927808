import { GET_HANDLE_PARENT_ACTIVITY } from "../actionTypes";

const initialState = {
  handleParentActivity: [
    { year: "", brand: "", budgetType: "", marsId: "", promoName: "", attachment: "", mabsklob: "" },
  ],
};

function getHandleParentActivity(state = initialState, action) {
  if (action.type === GET_HANDLE_PARENT_ACTIVITY) {
    return { ...state, handleParentActivity: action.payload };
  }
  return state;
}

export default getHandleParentActivity;
