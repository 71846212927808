import { GET_ALL_DEPARTMENT } from "../actionTypes";

const initialState = {
  departments: [],
};

function getAllDepartmentReducer(state = initialState, action) {
  if (action.type === GET_ALL_DEPARTMENT) {
    return { ...state, departments: action.payload };
  }
  return state;
}

export default getAllDepartmentReducer;
