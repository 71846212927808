/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import axios from "axios";
import { subMenuMapping } from "./subMenuMapping";
import { subMenuSettings } from "./subMenuSettings";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const [isApprover, setIsApprover] = useState("");
  const [idRole, setIdRole] = useState("");
  const [isAnalyst, setIsAnalyst] = useState(false);
  const [userTransfer, setUserTransfer] = useState(false);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  useEffect(() => {
    const user_token = localStorage.getItem("user_token");
    const fetchData = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/profile`,
        {
          headers: {
            token: user_token,
          },
        }
      );
      if (result.data.user.id_role === 10) {
        setIsAnalyst(true);
      }
      if (
        result.data.user.id_role === 4 ||
        result.data.user.id_role === 7 ||
        result.data.user.id_role === 8 ||
        result.data.user.id_role === 15
      ) {
        setUserTransfer(true);
      }
      setIsApprover(result.data.user.is_approver);
      setIdRole(result.data.user.id_role);
    };

    fetchData();
  }, []);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* Dashboard page */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/budget-submission",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/budget-submission">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} />
              {/* <i className="fas fa-money-bill-wave-alt text-danger"></i> */}
            </span>
            <span className="menu-text">Budget</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Budget</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/budget-submission/budgets"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/budget-submission/budgets">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Submission</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}

              {!isAnalyst && userTransfer && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/budget-submission/transfer"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/budget-submission/transfer"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Transfer</span>
                  </NavLink>
                </li>
              )}

              {/*end::2 Level*/}
              {/*begin::2 Level*/}

              {(isApprover || idRole === 8) && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/budget-submission/approval",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/budget-submission/approval"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Approval</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {/*begin::3 Level*/}
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/budget-submission/approvalbudgets"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/budget-submission/approvalbudgets"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Approval budgets</span>
                        </NavLink>
                      </li>
                      {/*end::3 Level*/}

                      {/*begin::3 Level*/}
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/budget-submission/approvaltransfer"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/budget-submission/approvaltransfer"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Approval transfer</span>
                        </NavLink>
                      </li>
                      {/*end::3 Level*/}
                    </ul>
                  </div>
                </li>
              )}

              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/activity-submission",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/activity-submission">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              {/* <i className="fas fa-money-bill-wave-alt text-danger"></i> */}
            </span>
            <span className="menu-text">Activity</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Activity</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/activity-submission/activities"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/activity-submission/activities"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">List of activity</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {(isApprover || idRole === 8) && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/activity-submission/approval",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/activity-submission/approval"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Approval</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {/*begin::3 Level*/}
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/activity-submission/approvalactivity"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/activity-submission/approvalactivity"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Approval activities</span>
                        </NavLink>
                      </li>
                      {/*end::3 Level*/}
                    </ul>
                  </div>

                  {(idRole === 1 || idRole === 7) && (
                    <>
                      <div className="menu-submenu">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                          {/*begin::3 Level*/}
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/activity-submission/approvalclose"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink
                              className="menu-link"
                              to="/activity-submission/approvalclose"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Approval close</span>
                            </NavLink>
                          </li>
                          {/*end::3 Level*/}
                        </ul>
                      </div>
                    </>
                  )}
                </li>
              )}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/actual-odoo",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/actual-odoo">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Clipboard-list.svg"
                )}
              />
            </span>
            <span className="menu-text">Actual</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Actual</span>
                </span>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/actual-odoo/actual"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/actual-odoo/actual">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">List of actual</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/report",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/report">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")}
              />
            </span>
            <span className="menu-text">Report</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Report</span>
                </span>
              </li>

              {[15, 13, 11, 10, 9, 8, 7, 6, 5, 4].includes(idRole) && (
                <>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/budget-report"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/budget-report">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        Budget Management Report
                      </span>
                    </NavLink>
                  </li>
                </>
              )}

              {[15, 11, 10, 7, 5, 3, 2, 1].includes(idRole) && (
                <>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/accrual-report"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/accrual-report">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Accrual</span>
                    </NavLink>
                  </li>
                </>
              )}
              <li
                className={`menu-item ${getMenuItemActive("/report/raw-data")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/report/raw-data">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Raw Data</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/report/mutation")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/report/mutation">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Mutation </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/* Mapping Data */}
        {[15, 7].includes(idRole) && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/mapping",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/setting">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Compass.svg")} />
              </span>
              <span className="menu-text">Mapping</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Mapping</span>
                  </span>
                </li>

                {subMenuMapping.map((item, idx) => (
                  <li
                    className={`menu-item ${getMenuItemActive(item.link)}`}
                    aria-haspopup="true"
                    key={idx}
                  >
                    <NavLink className="menu-link" to={item.link}>
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">{item.title}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        )}

        {/* Setting master data */}
        {[15, 7].includes(idRole) && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/setting",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/setting">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              <span className="menu-text">Setting</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Setting</span>
                  </span>
                </li>

                {subMenuSettings.map((item, idx) => (
                  <li
                    className={`menu-item ${getMenuItemActive(item.link)}`}
                    aria-haspopup="true"
                    key={idx}
                  >
                    <NavLink className="menu-link" to={item.link}>
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">{item.title}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        )}

        {[15, 10, 9, 8, 7, 4].includes(idRole) && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/setting-delegation",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/setting">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
                />
              </span>
              <span className="menu-text">Delegation</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Delegation</span>
                  </span>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive('/setting/setting-delegation')}`}
                  aria-haspopup="true"
                  key='delegation'
                >
                  <NavLink className="menu-link" to='/setting/setting-delegation'>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">'Delegation</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        )}
      </ul>
    </>
  );
}
