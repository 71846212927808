const initial = {
  inputKeys: [
    "groupCostType",
    "budgetType",
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
    "totalBudget",
    "arraySumMonth",
  ],
  availKeys: [
    "ava_jan",
    "ava_feb",
    "ava_mar",
    "ava_apr",
    "ava_may",
    "ava_jun",
    "ava_jul",
    "ava_aug",
    "ava_sep",
    "ava_okt",
    "ava_nov",
    "ava_dec",
  ],
};

function getKeysTransfer(state = initial, action) {
  return state;
}

export default getKeysTransfer;
