import { GET_CHILD_ACTIVITY } from "../actionTypes";

const initialState = {
	childActivity: [],
};

function getChildActivity(state = initialState, action) {
	if (action.type === GET_CHILD_ACTIVITY) {
		return { ...state, childActivity: action.payload };
	}
	return state;
}

export default getChildActivity;