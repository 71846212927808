/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { BreadCrumbs } from "../../subheader/components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../../_core/MetronicLayout";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const currentUrl = window.location.href;
  const splitCurrentUrl = currentUrl.split("/");
  const currentPage = splitCurrentUrl[4];

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  function handleSubHeader(header) {
    switch (header) {
      case "budgets":
        return "Budget Submission";
      case "create":
        return "New Budget";
      case "importnewbudget":
        return "Import New Budget";
      case "view":
        return "View Budget";
      case "edit":
        return "Edit Budget";
      case "transfer":
        return "Transfer Submission";
      case "inter-brand":
        return "Inter-Brand Transfer";
      case "cross-brand":
        return "Cross-Brand Transfer";
      case "activities":
        return "Activity";
      case "approvalactivity":
        return "Approval Activity";
      case "approvalclose":
        return "Approval Close";
      case "createactivities":
        return "New Activity";
      case "viewactivities":
        return "View Activity";
      case "editactivities":
        return "Edit Activity";
      case "list-of-user":
        return "User Management";
      case "approvalbudgets":
        return "Approval Budgets";
      case "approvaltransfer":
        return "Approval Transfer";
      case "adduser":
        return "Add User";
      case "view-user":
        return "View User";
      case "edit-user":
        return "Edit User";
      case "budget-report":
        return "";
      case "accrual-report":
        return "Accrual";
      case "actual":
        return "Actual";
      case "raw-data":
        return "Raw Data";
      default:
        return "";
    }
  }

  function handleBreadcrumbs(header) {
    switch (header) {
      case "budgets":
        let text1 =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/budget-submission" , "title":"Budget" },' +
          '{ "pathname":"/budget-submission/budgets" , "title":"List of budget" }]}';
        const headerBreadcrumbs1 = JSON.parse(text1);
        return headerBreadcrumbs1.breadcrumbs;
        break;
      case "transfer":
        let text1T =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/budget-submission" , "title":"Budget" },' +
          '{ "pathname":"/budget-submission/transfer" , "title":"List of transfer" }]}';
        const headerBreadcrumbs1T = JSON.parse(text1T);
        return headerBreadcrumbs1T.breadcrumbs;
        break;
      case "create":
        let text2 =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/budget-submission" , "title":"Budget" },' +
          '{ "pathname":"/budget-submission/budgets" , "title":"List of budget" },' +
          '{ "pathname":"/budget-submission/create" , "title":"Create new budget" }]}';
        const headerBreadcrumbs2 = JSON.parse(text2);
        return headerBreadcrumbs2.breadcrumbs;
        break;
      case "view":
        let text2V =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/budget-submission" , "title":"Budget" },' +
          '{ "pathname":"/budget-submission/budgets" , "title":"List of budget" },' +
          '{ "pathname":"/budget-submission/view" , "title":"View budget" }]}';
        const headerBreadcrumbs2V = JSON.parse(text2V);
        return headerBreadcrumbs2V.breadcrumbs;
        break;
      case "edit":
        let text2E =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/budget-submission" , "title":"Budget" },' +
          '{ "pathname":"/budget-submission/budgets" , "title":"List of budget" },' +
          '{ "pathname":"/budget-submission/edit" , "title":"Edit budget" }]}';
        const headerBreadcrumbs2E = JSON.parse(text2E);
        return headerBreadcrumbs2E.breadcrumbs;
        break;
      case "inter-brand":
        let text2T =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/budget-submission" , "title":"Budget" },' +
          '{ "pathname":"/budget-submission/transfer" , "title":"List of transfer" },' +
          '{ "pathname":"/budget-submission/inter-brand" , "title":"Inter-brand transfer" }]}';
        const headerBreadcrumbs2T = JSON.parse(text2T);
        return headerBreadcrumbs2T.breadcrumbs;
        break;
      case "cross-brand":
        let text2TC =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/budget-submission" , "title":"Budget" },' +
          '{ "pathname":"/budget-submission/transfer" , "title":"List of transfer" },' +
          '{ "pathname":"/budget-submission/cross-brand" , "title":"Cross-brand transfer" }]}';
        const headerBreadcrumbs2TC = JSON.parse(text2TC);
        return headerBreadcrumbs2TC.breadcrumbs;
        break;
      case "activities":
        let textact1 =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/activity-submission" , "title":"Activity" },' +
          '{ "pathname":"/activity-submission/activities" , "title":"List of activity" }]}';
        const headerBreadcrumbsAct1 = JSON.parse(textact1);
        return headerBreadcrumbsAct1.breadcrumbs;
        break;
      case "approvalactivity":
        let textapprovalact =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/activity-submission" , "title":"Approval Activity" },' +
          '{ "pathname":"/activity-submission/approvalactivity" , "title":"List of approval activity" }]}';
        const headerBreadcrumbsApprovalAct1 = JSON.parse(textapprovalact);
        return headerBreadcrumbsApprovalAct1.breadcrumbs;
        break;
      case "approvalclose":
        let textapprovalclose =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/activity-submission" , "title":"Approval Close" },' +
          '{ "pathname":"/activity-submission/approvalclose" , "title":"List of approval activity close" }]}';
        const headerBreadcrumbsApprovalClose = JSON.parse(textapprovalclose);
        return headerBreadcrumbsApprovalClose.breadcrumbs;
        break;
      case "createactivities":
        let textact2 =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/activity-submission" , "title":"Activity" },' +
          '{ "pathname":"/activity-submission/activities" , "title":"List of activity" },' +
          '{ "pathname":"/activity-submission/createactivities" , "title":"Create new activity" }]}';
        const headerBreadcrumbsAct2 = JSON.parse(textact2);
        return headerBreadcrumbsAct2.breadcrumbs;
        break;
      case "viewactivities":
        let textact3 =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/activity-submission" , "title":"Activity" },' +
          '{ "pathname":"/activity-submission/activities" , "title":"List of activity" },' +
          '{ "pathname":"/activity-submission/viewactivities" , "title":"View activity" }]}';
        const headerBreadcrumbsAct3 = JSON.parse(textact3);
        return headerBreadcrumbsAct3.breadcrumbs;
        break;
      case "editactivities":
        let textact4 =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/activity-submission" , "title":"Activity" },' +
          '{ "pathname":"/activity-submission/activities" , "title":"List of activity" },' +
          '{ "pathname":"/activity-submission/editactivities" , "title":"Edit activity" }]}';
        const headerBreadcrumbsAct4 = JSON.parse(textact4);
        return headerBreadcrumbsAct4.breadcrumbs;
        break;
      case "list-of-user":
        let textuser =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/user-management" , "title":"Setting" },' +
          '{ "pathname":"//list-of-user" , "title":"List of User" }]}';

        const headerBreadcrumbsUser = JSON.parse(textuser);
        return headerBreadcrumbsUser.breadcrumbs;
      case "approvaltransfer":
        let textapv =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/budget-submission" , "title":"Approval Budget" },' +
          '{ "pathname":"/budget-submission/approvaltransfer" , "title":"List of Transfer" }]}';

        const headerBreadcrumbsApv = JSON.parse(textapv);
        return headerBreadcrumbsApv.breadcrumbs;
      case "approvalbudgets":
        let textapvbudget =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/budget-submission" , "title":"Approval Budget" },' +
          '{ "pathname":"/budget-submission/approvalbudgets" , "title":"List of Budget" }]}';

        const headerBreadcrumbsApvBudgets = JSON.parse(textapvbudget);
        return headerBreadcrumbsApvBudgets.breadcrumbs;
      case "adduser":
        let textadduser =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/user-management" , "title":"Setting" },' +
          '{ "pathname":"/user-management/list-of-user" , "title":"List of User" }]}';

        const headerBreadcrumbsAddUser = JSON.parse(textadduser);
        return headerBreadcrumbsAddUser.breadcrumbs;
      case "view-user":
        let textviewuser =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/user-management" , "title":"Setting" },' +
          '{ "pathname":"/user-management/list-of-user" , "title":"List of User" }]}';

        const headerBreadcrumbsViewUser = JSON.parse(textviewuser);
        return headerBreadcrumbsViewUser.breadcrumbs;
      case "edit-user":
        let textedituser =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/user-management" , "title":"Setting" },' +
          '{ "pathname":"/user-management/list-of-user" , "title":"List of User" }]}';

        const headerBreadcrumbsEditUser = JSON.parse(textedituser);
        return headerBreadcrumbsEditUser.breadcrumbs;
      case "budget-report":
        let textbudgetreport =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/report" , "title":"Report" },' +
          '{ "pathname":"/report/budget-report" , "title":"Budget Management Report" }]}';

        const headerBreadcrumbsBudgetReport = JSON.parse(textbudgetreport);
        return headerBreadcrumbsBudgetReport.breadcrumbs;
      case "accrual-report":
        let textaccrualreport =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/report" , "title":"Report" },' +
          '{ "pathname":"/report/accrual-report" , "title":"Accrual" }]}';

        const headerBreadcrumbsAccrualReport = JSON.parse(textaccrualreport);
        return headerBreadcrumbsAccrualReport.breadcrumbs;
      case "actual":
        let textactualodoo =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/actual-odoo" , "title":"Actual" },' +
          '{ "pathname":"/actual-odoo/actual" , "title":"List Actual" }]}';

        const headerBreadcrumbsActualOdoo = JSON.parse(textactualodoo);
        return headerBreadcrumbsActualOdoo.breadcrumbs;
      case "raw-data":
        let textRawData =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/report" , "title":"Report" },' +
          '{ "pathname":"/report/raw-data" , "title":"Export Raw Data" }]}';

        const headerBreadcrumbsRawData = JSON.parse(textRawData);
        return headerBreadcrumbsRawData.breadcrumbs;
      case "mutation":
        let textMutation =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/report" , "title":"Report" },' +
          '{ "pathname":"/report/raw-data" , "title":"Mutation Report" }]}';

        const headerBreadcrumbsMutasiData = JSON.parse(textMutation);
        return headerBreadcrumbsMutasiData.breadcrumbs;
      case "setting-year":
        let textSettingYear =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/setting" , "title":"Setting" },' +
          '{ "pathname":"/setting/setting-year" , "title":"Setting Year" }]}';

        const headerBreadcrumbsSettingYear = JSON.parse(textSettingYear);
        return headerBreadcrumbsSettingYear.breadcrumbs;
      case "setting-brand":
        let textSettingBrand =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/setting" , "title":"Setting" },' +
          '{ "pathname":"/setting/setting-brand" , "title":"Setting Brand" }]}';

        const headerBreadcrumbsSettingBrand = JSON.parse(textSettingBrand);
        return headerBreadcrumbsSettingBrand.breadcrumbs;
      default:
        let text0 =
          '{ "breadcrumbs" : [' +
          '{ "pathname":"/budget-submission" , "title":"Budget" },' +
          '{ "pathname":"/budget-submission/budgets" , "title":"List of budget" }]}';
        const headerBreadcrumbs0 = JSON.parse(text0);
        return headerBreadcrumbs0.breadcrumbs;
    }
  }

  return (
    <div className="mt-4">
      {/* Info */}
      <div className="d-flex align-items-center flex-wrap mr-1">
        {layoutProps.subheaderMobileToggle && (
          <button
            className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
            id="kt_subheader_mobile_toggle"
          >
            <span />
          </button>
        )}

        <div className="d-flex align-items-baseline mr-5">
          <h5 className="text-dark font-weight-bold my-2 mr-5">
            <>
              {handleSubHeader(currentPage)}
              {/* {subheader.title} */}
            </>
            {/*<small></small>*/}
          </h5>
        </div>

        <BreadCrumbs items={handleBreadcrumbs(currentPage)} />
      </div>
    </div>
  );
}
